.main-container {
  color: #fff;
}

h2.main_title {
  font-size: 39px;
  color: #a37bb6;
  text-align: center;
  margin-top: 20px;
}

.about_us .about_us_content table tr td p {
  text-align: left !important;
}

.page-membership-details .about_us,
.page-ta-101-program-details .about_us {
  max-width: 1258px;
  margin: 0px auto;
}

.page-membership-details .about_us .about_us_wrapper h2,
.page-ta-101-program-details .about_us .about_us_wrapper h2 {
  text-align: left;
  width: 100%;
  font-size: 30px;
  color: #a37bb6;
  margin-left: 20px;
  margin-top: 20px;
}

.page-membership-details .about_us .about_us_content p,
.page-ta-101-program-details .about_us .about_us_content p {
  font-size: 15px;
  padding-bottom: 12px;
  margin-top: 0px !important;
}

.page-membership-details .about_us .about_us_content ul,
.page-ta-101-program-details .about_us .about_us_content ul {
  transform: translateX(21px);
}

.page-membership-details .about_us .about_us_content table,
.page-ta-101-program-details .about_us .about_us_content table {
  border: 1px solid;
  width: 100%;
  border-collapse: collapse;
}

.page-membership-details .about_us .about_us_content table tr th,
.page-ta-101-program-details .about_us .about_us_content table tr th {
  border-bottom: 1px solid black;
  background-color: #a37bb6;
  color: white;
}

.page-membership-details .about_us .about_us_content table tr th p,
.page-ta-101-program-details .about_us .about_us_content table tr th p {
  padding: 20px 18px;
  font-size: 15px;
  margin-top: 0px !important;
}

.page-membership-details .about_us .about_us_content table tr:nth-child(3),
.page-ta-101-program-details .about_us .about_us_content table tr:nth-child(3),
.page-membership-details .about_us .about_us_content table tr:nth-child(5),
.page-ta-101-program-details .about_us .about_us_content table tr:nth-child(5) {
  background-color: #a37bb6 4d;
}

.page-membership-details .about_us .about_us_content table tr td,
.page-ta-101-program-details .about_us .about_us_content table tr td {
  padding: 20px 0px;
  font-size: 15px;
  border-bottom: 1px solid black;
}

.page-membership-details .about_us .about_us_content table tr td p,
.page-ta-101-program-details .about_us .about_us_content table tr td p {
  padding: 0px 18px;
}

.page-membership-details .about_us .about_us_content table tr td p a,
.page-ta-101-program-details .about_us .about_us_content table tr td p a {
  text-decoration: none;
}

.page-membership-details .about_us::before,
.page-ta-101-program-details .about_us::before {
  background: none;
}

.page-ta-101-program-details .about_us_content table {
  margin: 60px 0px 30px 0px;
}

.page-ta-101-program-details .about_us_content table tr th p {
  text-align: center;
}

.page-ta-101-program-details .about_us_content table tr td p {
  text-align: center;
}

.newsletter-description {
  margin-left: 24px;
}

.newsletter-description ul {
  margin-left: 24px;
}

.newsletter-description b {
  display: block;
}

* {
  margin: 0px;
  padding: 0;
  box-sizing: border-box;
}

.login_btn {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  background: #a37bb6;
  cursor: pointer;
}

.login_btn a {
  text-decoration: none;
  color: white;
  font-size: 17px;
}

.news-letter-subtitle {
  display: flex;
  justify-content: space-between;
  margin: auto;
  gap: 19px;
}

.news-letter-subtitle img {
  max-width: 351px;
}

.news-letter-subtitle p {
  line-height: 36px;
  font-size: 15px;
}

.news-main {
  max-width: 1276px;
  margin: 0px auto;
  padding: 0px 12px;
}

.news-main h1 {
  border: 1px solid;
  text-align: center;
  padding: 10px 0px;
  background-color: #e84c3d;
  color: white;
  font-size: 29px;
  margin-bottom: 20px;
}

.newsletter {
  max-width: 1276px;
  margin: 0px auto;
  padding: 0px 12px;
}

.newsletter a {
  display: flex;
  justify-content: flex-end;
  padding: 30px 0px;
  font-size: 20px;
  color: black;
  text-decoration: none;
}

.newsletter-description p {
  margin-bottom: 15px;
  line-height: 26px;
  font-size: 15px;
}

.newsletter-description p:nth-child(8) {
  display: flex;
  flex-direction: column;
}

.newsletter_banner_img img {
  width: 100%;
  margin-bottom: 10px;
}

.news-letter-container {
  display: flex;
  width: 100%;
  gap: 27px;
}

.news-letter-folder1 {
  width: 77%;
}

.news-letter-folder2 {
  width: 23%;
}

.news-field {
  list-style: none;
  padding: 17px;
  text-align: center;
  margin: 25px 0px;
  background-color: #e84c3d;
  color: white;
  cursor: pointer;
}

.news-drop_drown-menu {
  padding-left: 24px;
}

.news-drop_drown-menu li {
  margin-bottom: 10px;
}

.news-latter-right-field-folder ul div:nth-child(2) .news-field {
  background: #438b8b;
}

.news-latter-right-field-folder ul div:nth-child(3) .news-field {
  background: #da9a31;
}

.news-latter-right-field-folder ul div:nth-child(4) .news-field {
  background: #996ada;
}

.news-latter-right-field-folder ul div:nth-child(5) .news-field {
  background: #9cc255;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Top-Header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
}

.logo img {
  max-width: 100%;
  width: 250px;
}

.menu {
  display: flex;
  align-items: center;
}

.menu li {
  list-style: none;
}

.menu li a {
  text-decoration: none;
  margin: 0px 10px;
  font-size: 16px;
  color: #757575;
}

.Top-Header-wrapper-folder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
}

.Top-Header-wrapper-folder .social_icon a {
  padding: 0px;
  margin-bottom: 0px;
}

.logo-folder img {
  max-width: 339px;
}

.menu-folder {
  display: flex;
  align-items: center;
  gap: 20px;
}

.menu-folder li {
  list-style: none;
}

.menu-folder li a {
  text-decoration: none;
  /* margin: 0px 10px; */
  font-size: 16px;
  color: #757575;
  /* padding: 10px 0px; */
}

.Top-header-section {
  display: none;
}

.banner-slider-image img {
  width: 100%;
  height: 470px;
}

.banner-slider-folder .slick-prev {
  left: 11px;
  z-index: 2;
}

.banner-slider-folder .slick-prev:before {
  font-size: 40px !important;
  line-height: 1;
  opacity: 1;
  color: #ffffff !important;
}

.banner-slider-folder .slick-next {
  right: 30px;
}

.banner-slider-folder .slick-next:before {
  font-size: 40px !important;
  line-height: 1;
  opacity: 1;
  color: #ffffff !important;
}

.gallery-slider-folder .slick-prev:before {
  font-size: 40px !important;
  line-height: 1;
  opacity: 0.75;
  color: #262626 !important;
}

.gallery-slider-folder .slick-next:before {
  font-size: 40px !important;
  line-height: 1;
  opacity: 0.75;
  color: #262626 !important;
}

.info-container {
  text-align: center;
  padding: 30px 0px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-container:nth-child(odd) {
  background-color: #a37bb6;
  color: white;
}

.info-container:nth-child(odd) .read-more {
  background: #fffdfd;
  color: #a37bb6;
}

.info-container:nth-child(odd) .info-title {
  color: #fff;
}

.info-container:nth-child(odd) .info-desc {
  color: white;
}

.info-title {
  font-size: 39px;
  color: #a37bb6;
  position: relative;
}

.info-title::before {
  background: #a37bb6;
  bottom: -15px;
  content: "";
  height: 4px;
  left: 0%;
  right: 0%;
  margin: 0px auto;
  position: absolute;
  width: 80px;
}

.info-desc {
  font-size: 18px;
  line-height: 31px;
  max-width: 1170px;
  margin: 40px auto 40px auto;
  color: #6f6f6f;
}

.read-more {
  padding: 13px 25px;
  font-size: 17px;
  border-radius: 8px;
  border: none;
  background: #a37bb6;
  color: white;
  cursor: pointer;
}

.board-of-members-blog-box-section {
  width: 300px;
  text-align: center;
}

.board-of-members-blog-box-section h2 {
  font-size: 27px;
  margin: 33px 0px 0px 0px;
  color: #fffdfd;
}

.board-of-members-blog-box-section p {
  font-size: 17px;
  color: #fffdfd;
  font-style: italic;
}

.board-of-members-image {
  max-width: 240px;
  margin: 0px auto;
}

.board-of-members-image img {
  width: 100%;
object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  height: 250px;
}

.board-of-members-section {
  background: url("../Images/12232.jpg") no-repeat;
  background-position: bottom;
  background-size: inherit;
  background-attachment: fixed;
}

.board-of-members-folder {
  justify-content: center;
  flex-wrap: wrap;
  gap: 70px;
}

.board-of-members-section.info-container {
  display: block;
}

.board-of-members-wrapper {
  max-width: 1270px;
  margin: auto;
}

.Board-of-Members-title {
  text-align: center;
  font-size: 35px;
  margin: 0px 0px 41px 0px;
  color: #fff;
}

.Board-of-Members-title::before {
  background-color: #fff;
}

.contact {
  align-items: center;
  justify-content: center;
  margin: 56px 0px;
}

.input_wrapper {
  display: flex;
  gap: 12px;
}

.contact_form {
  background: #a37bb6;
  width: 500px;
  height: auto;
  padding: 19px 20px 39px 20px;
  border-radius: 15px;
}

.contact_form h2 {
  font-size: 40px;
  color: #fff;
  margin: 6px 0px;
}

.contact_form p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 22px;
}

.contact_form .input_field {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
}

.contact_form .input_field i.input-icons {
  position: absolute;
  right: 10px;
  font-size: 18px;
  color: #a37bb6;
}

.contact_form input {
  padding: 13px 0px 13px 20px;
  font-size: 17px;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #757575;
  color: #757575;
}

.contact_form textarea {
  margin: 13px 0px;
  font-size: 18px;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #757575;
  color: #757575;
  padding: 13px 0px 0px 20px;
}

.map {
  margin-top: 3px;
}

.input-icons {
  position: absolute;
  top: 44%;
  right: 6%;
  transform: translateY(-50%);
  font-size: 18px;
  color: #a37bb6;
  display: flex;
}

.map iframe {
  border-radius: 15px;
  height: 550px;
  border: none;
}

.contact_wrapper {
  display: flex;
  max-width: 1262px;
  margin: 0px auto;
}

.footer {
  background-color: #a37bb6;
}

.footer_wrapper {
  margin: 10px 30px;
  margin-bottom: 0px;
}

.get_d_fex {
  display: flex;
  margin: auto;
  align-items: flex-end;
  justify-content: center;
  gap: 30%;
  min-height: 210px;
  border-bottom: 1px solid #fffdfd;
  padding-bottom: 43px;
  margin-bottom: 27px;
}

.footer_logo img {
  width: 198px;
  margin-top: 32px;
  margin-bottom: 12px;
}

.footer_menu {
  display: flex;
  justify-content: center;
  margin: auto;
}

.footer_menu ul {
  list-style: none;
}

.footer_menu ul a {
  text-decoration: none;
  color: #fffdfd;
  font-size: 16px;
}

.footer_menu li {
  margin: 10px;
}

.social_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;
}

.social_icon a {
  text-decoration: none;
  font-size: 21px;
  margin: 0 6px;
  border-radius: 50px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: #fff;
  margin-bottom: 10px;
}

.social_icon a > .fa-facebook {
  color: #1d41ff;
}

.social_icon a > .fa-twitter {
  color: #0085ff;
}

.social_icon a > .fa-youtube {
  color: #f00;
}

.copy_right {
  padding: 8px 0px;
}

.copy_right p {
  text-align: center;
  color: #fffdfd;
  margin: 0;
  padding: 0;
}

.show-btn {
  position: absolute;
  top: 10%;
  right: 5%;
  font-size: 30px;
  color: black;
  z-index: 3;
  display: none;
}

.gallery-slider-image {
  width: 100%;
  height: 709px;
}

.gallery-slider-image iframe {
  width: 100%;
  height: 100%;
}

.ITAA-The-Dance-of-Culture-image {
  max-width: 366px;
  margin: 0px auto;
}

.ITAA-The-Dance-of-Culture-image img {
  width: 100%;
}

.gallery-South-Asian-Association-image {
  max-width: 366px;
  margin: 0px auto;
}

.gallery-South-Asian-Association-image img {
  width: 100%;
}

.ITAA-The-Dance-of-Culture-folder {
  max-width: 1254px;
  margin: 0px auto;
}

.ITAA-The-Dance-of-Culture-folder .slick-slide {
  opacity: 0.5;
}

.ITAA-The-Dance-of-Culture-folder .slick-current.slick-active {
  opacity: 1;
  width: 300px;
}

.ITAA-The-Dance-of-Culture-folder
  .slick-slide.slick-active.slick-center.slick-current
  .ITAA-The-Dance-of-Culture-image {
  max-width: 391px;
}

.gallery-South-Asian-Association-folder {
  max-width: 1254px;
  margin: 0px auto;
}

.gallery-South-Asian-Association-folder .slick-slide {
  opacity: 0.5;
}

.gallery-South-Asian-Association-folder .slick-current.slick-active {
  opacity: 1;
  width: 300px;
}

.gallery-South-Asian-Association-folder
  .slick-slide.slick-active.slick-center.slick-current
  .gallery-South-Asian-Association-image {
  max-width: 391px;
}

.ITAA-The-Dance-of-Culture-container h2 {
  text-align: center;
  font-size: 27px;
  color: #6f6f6f;
  max-width: 861px;
  margin: 81px auto 50px auto;
}

.gallery-South-Asian-Association-container h2 {
  text-align: center;
  font-size: 27px;
  color: #6f6f6f;
  max-width: 861px;
  margin: 81px auto 50px auto;
}

.gallery-section .slick-next {
  right: 38px;
}

.gallery-section .slick-prev {
  left: 21px;
  z-index: 1;
}

.membership-registration-card {
  width: 280px;
  text-align: center;
  padding-bottom: 52px;
  position: relative;
  box-shadow: 0px 4px 8px #e9e9e9;
}

.membership-registration-card::after {
  content: "";
  position: absolute;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 7px;
  background-color: #a37bb6;
}

.membership-registration-text h2 {
  margin: 0px;
  padding: 20px 0px;
  background-color: #a37bb6;
  color: white;
  font-size: 14px;
}

.membership-registration-image {
  max-width: 220px;
  margin: 39px auto 25px auto;
}

.membership-registration-image img {
  width: 100%;
}

.plan-price {
  font-size: 52px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 10px 0px 0px 0px;
  color: #6f6f6f;
}

.plan-price a {
  font-size: 19px !important;
  color: #6f6f6f;
}

.Billed-Yearly {
  font-size: 12px !important;
  margin: 0px;
  color: #6f6f6f;
}

.membership-subscribe-btn {
  margin: 39px 0px 0px 0px;
}

.membership-subscribe-btn a {
  border: 1px solid;
  padding: 16px 49px;
  text-decoration: none;
  border-radius: 61px;
  margin: 10px;
  background-color: #a37bb6;
  color: white;
  font-size: 14px;
}

.membership-registration-card-des {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 11px;
  margin: 0px 15px -17px 15px;
}

.membership-registration-card-des a {
  font-size: 17px;
  color: #a37bb6;
}

.membership-registration-card-des p {
  font-size: 14px;
  color: #6f6f6f;
}

.membership-registration-card-folder {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 65px 0px 50px 0px;
}

.Membership-Registration-title {
  font-size: 25px;
  margin-left: 90px;
  color: #6f6f6f;
}

.Membership-Registration-text {
  font-size: 25px;
  margin-left: 90px;
  color: #6f6f6f;
}

.Membership-Registration-text a {
  font-size: 25px;
  color: #7070ff;
}

.membership-registration-card-des-folder {
  height: 95px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.about_us {
  width: 1258px;
  margin: auto;
}

/* .about_us::before {
  position: absolute;
  background: #3870b5;
  content: '';
  top: -70px;
  left: 0;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 250px;
  text-align: center;
  z-index: -1;
} */

.aboutus_parent {
  margin: 0px 20px;
}

.about_us_wrapper {
  z-index: 1;
}

.about_us_wrapper h2 {
  text-align: left;
  width: 100%;
  font-size: 30px;
  color: #a37bb6;
  margin-left: 20px;
  margin-top: 20px;
}

/* .about_us_wrapper h2::before {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0%;
  width: 100%;
  height: 2px;
  background: #fff;
} */

.about_us_img {
  max-width: 800px;
  width: 800px;
  margin: auto;
  margin-top: 10px;
}

.about_us_img img {
  max-width: 800px;
  width: 800px;
  margin: auto;
  margin-top: 10px;
}

.about_us_content p {
  text-align: justify;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}

.Certified-Members-card {
  border: 1px solid;
  max-width: 300px;
  padding-bottom: 20px;
  border-radius: 20px;
  position: relative;
  border: 1px solid #d7d7d7;
  max-width: 260px;
  padding-bottom: 20px;
  border-radius: 10px;
  text-align: center;
}

.Certified-Members-card h2 {
  font-size: 17px;
  margin: 12px 0px 5px 0px;
}

.Certified-Members-card:hover .Certified-Members-details-folder {
  opacity: 1;
}

.Certified-Members-image img {
  width: 100%;
  border-radius: 10px;
}

.Certified-Members-folder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}

.Certified-Members-details-folder {
  text-align: left;
  border: 1px solid;
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #a37bb6;
  color: white;
  padding: 24px 21px;
  border-radius: 10px;
  opacity: 0;
  transition: all 0.2s ease-in;
}

.Certified-Members-details {
  display: flex;
  gap: 6px;
  align-items: baseline;
  margin-bottom: -9px;
}

.Certified-Members-details h2 {
  font-size: 13px !important;
}

.Certified-Members-details p {
  font-size: 13px !important;
  line-height: 19px;
}

.Certified-Members-text {
  font-size: 30px;
  max-width: 336px;
  text-align: end;
  margin: 23px 0px 27px 0px;
  color: #a37bb6;
}

.Membership-folder {
  padding-left: 0px;
  position: absolute;
  top: 24px;
  width: 189px;
  z-index: 2;
  background-color: white;
  display: none;
  transition: all 0.3s ease-in;
}

.Membership-folder:hover .Membership-folder li {
  color: white;
}

.Membership-folder li {
  border-bottom: 1px solid #757575 4a;
  padding: 10px 6px;
}

.Membership-folder li:hover {
  background-color: #a37bb6;
  color: white;
}

.Membership-folder li a:hover {
  color: white;
}

.Certifications {
  position: relative;
}

.Certifications:hover .Certifications-folder {
  display: block;
}

.Membership {
  position: relative;
}

.Membership:hover .Membership-folder {
  display: block;
}

.directory {
  position: relative;
}

.directory:hover .directory-folder {
  display: block;
}

.Programs {
  position: relative;
}

.Programs:hover .Programs-folder {
  display: block;
}

.menu_item ul {
  width: 100%;
}

.Awards {
  position: relative;
}

.Awards:hover .Awards-folder {
  display: block;
}

.Newsletter {
  position: relative;
}

.Newsletter:hover .Newsletter-folder {
  display: block;
}

.Certifications-folder {
  padding-left: 0px;
  position: absolute;
  top: 23px;
  width: 189px;
  z-index: 2;
  background-color: white;
  display: none;
  transition: all 0.3s ease-in;
}

.Certifications-folder li {
  border-bottom: 1px solid #757575 4a;
  padding: 10px 6px;
}

.Certifications-folder li:hover {
  background-color: #a37bb6;
  color: white;
}

.Certifications-folder li a:hover {
  color: white;
}

.directory-folder {
  padding-left: 0px;
  position: absolute;
  top: 23px;
  width: 189px;
  z-index: 2;
  background-color: white;
  display: none;
  transition: all 0.3s ease-in;
}

.directory-folder li {
  border-bottom: 1px solid #757575 4a;
  padding: 10px 6px;
}

.directory-folder li:hover {
  background-color: #a37bb6;
  color: white;
}

.directory-folder li a:hover {
  color: white;
}

.Programs-folder {
  padding-left: 0px;
  position: absolute;
  top: 23px;
  width: 189px;
  z-index: 2;
  background-color: white;
  display: none;
  transition: all 0.3s ease-in;
  padding-left: 0px;
  position: absolute;
  top: 23px;
  width: 237px;
  z-index: 2;
  background-color: white;
  display: none;
  transition: all 0.3s ease-in;
}

.Programs-folder li {
  border-bottom: 1px solid #757575 4a;
  padding: 10px 6px;
}

.Programs-folder li:hover {
  background-color: #a37bb6;
  color: white;
}

.Programs-folder li a:hover {
  color: white;
}

.Awards-folder {
  padding-left: 0px;
  position: absolute;
  top: 23px;
  width: 243px;
  z-index: 2;
  background-color: white;
  display: none;
  transition: all 0.3s ease-in;
}

.Awards-folder li {
  border-bottom: 1px solid #757575 4a;
  padding: 10px 6px;
}

.Awards-folder li:hover {
  background-color: #a37bb6;
  color: white;
}

.Awards-folder li a:hover {
  color: white;
}

.Newsletter-folder {
  padding-left: 0px;
  position: absolute;
  top: 23px;
  width: 170px;
  z-index: 2;
  background-color: white;
  display: none;
  /* transition: all 0.3s ease-in; */
}

.Newsletter-folder li {
  border-bottom: 1px solid #757575 4a;
  padding: 10px 6px;
}

.Newsletter-folder li:hover {
  background-color: #a37bb6;
  color: white;
}

.Newsletter-folder li a:hover {
  color: white;
}

.Articles-blog-section {
  max-width: 1152px;
  margin: 0px auto;
  padding-bottom: 30px;
}

.Articles-blog-section h2 {
  font-size: 40px;
  margin: 0px 0px 7px 0px;
}

.artical-author-folder {
  display: flex;
  gap: 12px;
  align-items: flex-end;
  margin-top: 40px;
}

.artical-author-image img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.Articles-date {
  font-size: 14px;
  margin: 0px 0px 0px 0px;
}

.Articles-image {
  margin: 52px 0px 35px 0px;
  max-width: 1152px;
}

.Articles-image img {
  width: 100%;
  height: 600px;
}

.Articles-Author {
  font-size: 16px;
  line-height: 30px;
}

.Articles-Author span {
  font-weight: 700;
}

.Articles-des {
  font-size: 16px;
  line-height: 30px;
}

.artical-blog-box-section {
  width: 363px;
  border-radius: 15px;
  box-shadow: 0px 4px 6px #e5e5e5;
}

.artical-blog-box-section h2 {
  font-size: 22px;
  margin: 14px 20px 14px 20px;
}

.artical-image img {
  width: 100%;
  height: 283px;
  border-radius: 15px;
}

.artical-des {
  font-size: 15px;
  margin: 0px 20px 0px 20px;
  color: #757575;
}

.artical-date-folder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid;
  margin: 18px 20px 24px 20px;
  padding-top: 14px;
}

.artical-date-folder a {
  font-size: 16px;
  color: #9818d5;
  text-decoration: none;
}

.artical-date {
  font-size: 16px;
  color: #757575;
}

.artical-blog-folder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 31px;
}

.artical-text {
  text-align: center;
  font-size: 30px;
  margin: 30px 0px 50px 0px;
  max-width: 481px;
  display: flex;
  justify-content: flex-end;
  color: #a37bb6;
}

.Artical-wrapper {
  margin-bottom: 50px;
}

.Certified-Members-wrapper {
  margin-bottom: 50px;
}

.gallery-wrapper {
  margin-bottom: 50px;
}

.product-image img {
  width: 200px;
}

.Certified-Members-wrapper,
.Artical-wrapper {
  max-width: 1258px;
  margin: 0px auto;
}

.Certified-Members-details li {
  font-size: 14px;
  list-style: none;
  margin-bottom: 16px;
}

.Certified-Members-details span {
  font-weight: 700;
  font-size: 15px;
}

.activeLink {
  background-color: #c2c2c2;
  font-weight: bold;
}

/* new css for allLinks page  */
.news-latter-cpnews-section {
  background-color: hsla(0, 0%, 98%, 0.239);
  box-shadow: 0 4px 17px #e5e5e5;
  margin: 0 auto 37px;
  max-width: 1258px;
  padding: 18px 20px;
}

.news-latter-cpnews-section h2 {
  color: #a37bb6;
  font-size: 30px;
  margin-left: 20px;
  margin-top: 20px;
  width: 100%;
}

.news-latter-cpnews-section > div {
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  margin: 30px 0;
}

.news-latter-cpnews-section > div p {
  box-shadow: 0 4px 13px #e5e5e5;
  list-style: none;
  padding: 40px 0;
  text-align: center;
  width: 280px;
  margin: auto;
}

.news-latter-cpnews-section > div p a {
  color: #a37bb6;
  text-decoration: none;
  /* font-weight: bold; */
}

.news-latter-cpnews-section > div p a b {
  font-weight: 300;
}

body.page-ta-101-program-details td {
  border-right: 1px solid #cdcdcd;
}

/* top header menu css */
ul .menu_item:hover > a {
  /* color: #a37bb6 !important; */
  border-bottom: 1px solid;
}

.menu-folder {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.menu_item {
  position: relative;
  padding: 10px 0;
  cursor: pointer;
}

.menu_item > a {
  color: #333;
  text-decoration: none;
}

/* .menu_item:hover>a {
  color: #996ada;
} */

/* .menu_item:hover ul {
  display: block;
} */

.menu_item ul li {
  padding: 0px 16px;
}

.menu_item ul li a {
  color: #333;
  text-decoration: none;
}

.menu_item ul li a:hover {
  color: #fff !important;
}

.Top-Header-wrapper-folder .social_icon a i {
  color: #a37bb6 !important;
}

.page-saata-conference-registration table {
  border-collapse: collapse;
  overflow-x: auto;
  width: 100%;
  margin-top: 50px;
}

.page-saata-conference-registration th {
  background-color: rgba(163, 123, 182, 0.302);
}

.page-saata-conference-registration th {
  border: 1px solid;
  font-size: 19px;
  font-weight: bold;
}

.page-saata-conference-registration th,
.page-saata-conference-registration td {
  padding: 15px 8px;
  font-size: 16px;
  border: 1px solid;
}

.page-saata-conference-registration tr:nth-child(even) {
  background-color: #f2f2f2;
}

.page-saata-conference-registration table a {
  background: #a37bb6;
  padding: 10px;
  text-decoration: none;
  color: #fff;
  font-size: 15px;
  border-radius: 8px;
}

.page-saata-conference-registration th p,
.page-saata-conference-registration tr p {
  text-align: left;
}

.page-saata-conference table {
  border-collapse: collapse;
  margin-top: 50px;
  overflow-x: auto;
  width: 100%;
}

.page-saata-conference table td,
.page-saata-conference table th {
  border: 1px solid #000 !important;
  font-size: 16px;
  padding: 15px 8px;
}

.page-saata-conference table th p {
  color: #000;
}

.page-saata-conference table th {
  font-size: 19px;
  font-weight: 700;
  background-color: rgba(163, 123, 182, 0.302) !important;
}

.page-saata-conference table a {
  background: #a37bb6;
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  padding: 10px;
  text-decoration: none;
}

.container {
  width: 90%;
  margin: 20px auto;
}

.collapsible {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button {
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  text-align: left;
  cursor: pointer;
}

.Content {
  padding: 10px;
}

.Content > div {
  margin-top: 10px;
}

.Content img {
  max-width: 100%;
  height: auto;
}

.collapsible .button {
  background-color: #a37bb6;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  font-size: 20px;
  margin: auto;
  padding: 12px;
}
.collapsible .title {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.collapsible .button span {
  font-size: 25px;
  margin-left: 35px;
}
.Content {
  transition: max-height 0.3s ease;
}
.page-memorial-endowment-lecture .about_us_content p,
.page-p-k-saru-memorial-endowment-fund .about_us_content p,
.page-memorial-endowment-lecture .about_us_wrapper h2,
.page-p-k-saru-memorial-endowment-fund .about_us_wrapper h2,
.page-memorial-endowment-lecture .about_us_wrapper,
.page-p-k-saru-memorial-endowment-fund .about_us_wrapper {
  text-align: center;
}

.page-memorial-endowment-lecture .about_us_content p,
.page-p-k-saru-memorial-endowment-fund .about_us_content p {
  padding: 6px 0;
}

.page-memorial-endowment-lecture .about_us_content h5,
.page-p-k-saru-memorial-endowment-fund .about_us_content h5 {
  /*   color: #faf010; */
  padding: 12px 0;
  font-size: 25px;
}

.page-memorial-endowment-lecture .about_us_content h6,
.page-p-k-saru-memorial-endowment-fund .about_us_content h6 {
  /*       color: #faf010; */
  padding: 12px 0;
  margin: 5px 0;
  font-size: 20px;
}
.page-p-k-saru-memorial-endowment-fund .about_us_content h4{
  font-weight: 400;
}
.page-p-k-saru-memorial-endowment-fund .about_us_content h4 a{
  background: #fffdfd;
  color: #a37bb6;
}
/* end  */
@media only screen and (max-width: 1024px) {
  .banner-slider-image img {
    width: 100%;
    height: 354px;
  }

  .info-desc {
    font-size: 18px;
    max-width: 769px;
    margin: 40px auto 40px auto;
  }

  .board-of-members-image {
    max-width: 281px;
    margin: 0px auto;
  }

  .board-of-members-blog-box-section h2 {
    font-size: 29px;
    margin: 33px 0px -11px 0px;
  }

  .board-of-members-blog-box-section p {
    font-size: 19px;
  }

  iframe {
    width: 704px;
  }

  .contact_form {
    width: 514px;
    padding: 30px 25px 42px 25px;
  }

  .contact_form h2 {
    font-size: 49px;
  }

  .contact_form p {
    font-size: 21px;
  }

  .contact_wrapper {
    display: flex;
    gap: 18px;
    flex-direction: column;
    align-items: center;
    max-width: 1262px;
  }

  .map {
    height: auto;
  }

  .submit_btn button {
    padding: 15px 40px;
    font-size: 22px;
  }

  .footer_menu ul {
    list-style: none;
    padding-left: 0px;
  }

  .get_d_fex {
    display: flex;
    gap: 23%;
  }

  .logo img {
    max-width: 305px;
    max-width: 305px;
  }

  .menu-folder {
    margin: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  .menu-folder li a {
    margin: 0px 4px;
    margin: 0px 4px;
  }

  .board-of-members-folder .slick-next {
    right: 1px;
    right: 1px;
  }

  .board-of-members-folder .slick-prev {
    left: 5px;
    left: 5px;
  }

  .ITAA-The-Dance-of-Culture-image {
    max-width: 299px;
    margin: 0px auto;
    max-width: 299px;
    margin: 0px auto;
  }

  .gallery-South-Asian-Association-image {
    max-width: 299px;
    margin: 0px auto;
    max-width: 299px;
    margin: 0px auto;
  }

  .contact_form textarea {
    width: 459px;
  }
}

@media only screen and (max-width: 820px) {
  .get_d_fex {
    display: flex;
    gap: 13%;
  }

  .contact_form textarea {
    width: 461px;
  }

  .page-membership-details .about_us,
  .page-ta-101-program-details .about_us,
  .page-exam-dates .about_us,
  .page-saata-calendar .about_us,
  .page-examination-forms .about_us,
  .page-examination-documents .about_us,
  .page-directory .about_us,
  .page-membership-renewal .about_us,
  .page-call-for-nominations .about_us,
  .page-guidelines-for-content-contributors .about_us,
  .page-sajta-submission-guidelines
    .about_us
    .page-newsletter-advertisement-policy
    .about_us,
  .page-journals .about_us,
  .page-awards .about_us,
  .page-service-award .about_us,
  .page-about-us .about_us,
  .page-about-ta .about_us,
  .page-history-and-origin-of-saata .about_us,
  .page-presidents-note .about_us,
  .page-mission-and-vision .about_us,
  .page-eric-berne .about_us,
  .page-saata-bot .about_us,
  .page-enthusiastic-explorer .about_us,
  .page-exuberant-organiser .about_us,
  .page-story-teller .about_us,
  .page-truth-seeker .about_us,
  .page-ace-conceptualist .about_us,
  .page-gregarious-hugger .about_us,
  .page-team-that-was .about_us,
  .page-membership-details .about_us,
  .page-ta-101-program-details .about_us,
  .page-exam-dates .about_us,
  .page-saata-calendar .about_us,
  .page-examination-forms .about_us,
  .page-examination-documents .about_us,
  .page-directory .about_us,
  .page-membership-renewal .about_us,
  .page-call-for-nominations .about_us,
  .page-guidelines-for-content-contributors .about_us,
  .page-sajta-submission-guidelines .about_us,
  .page-newsletter-advertisement-policy .about_us,
  .page-journals .about_us,
  .page-awards .about_us,
  .page-service-award .about_us,
  .page-about-us .about_us,
  .page-about-ta .about_us,
  .page-history-and-origin-of-saata .about_us,
  .page-presidents-note .about_us,
  .page-mission-and-vision .about_us,
  .page-eric-berne .about_us,
  .page-saata-bot .about_us,
  .page-enthusiastic-explorer .about_us,
  .page-exuberant-organiser .about_us,
  .page-story-teller .about_us,
  .page-truth-seeker .about_us,
  .page-ace-conceptualist .about_us,
  .page-gregarious-hugger .about_us,
  .page-team-that-was .about_us {
    max-width: 784px !important;
  }
}
.page-memorial-endowment-lecture .aboutus_parent,
.page-p-k-saru-memorial-endowment-fund .aboutus_parent,
.page-memorial-endowment-lecture .about_us_content,
.page-p-k-saru-memorial-endowment-fund .about_us_content {
  text-align: center;
}

.page-memorial-endowment-lecture .about_us_content p a,
.page-p-k-saru-memorial-endowment-fund .about_us_content p a {
  background: #a37bb6;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
}
.about_us_content table td:last-child a{
  color: #0085ff;
  text-decoration: underline !important;
}
@media only screen and (max-width: 768px) {
 .page-memorial-endowment-lecture .about_us,
.page-p-k-saru-memorial-endowment-fund .about_us {
  width: 100%;
}

.page-memorial-endowment-lecture .about_us_wrapper h2,
.page-p-k-saru-memorial-endowment-fund .about_us_wrapper h2 {
  margin-left: 0;
}

.page-memorial-endowment-lecture iframe,
.page-p-k-saru-memorial-endowment-fund iframe {
  max-width: 100%;
}
  .news-latter-cpnews-section > div {
    justify-content: center;
  }

  .Top-header-section {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 5;
  }

  .Top-Header-wrapper {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }

  .menu li {
    margin: 8px 0px;
    border-bottom: 1px solid;
    width: 100%;
    text-align: center;
    padding-bottom: 14px;
  }

  .menu li a {
    font-size: 25px;
  }

  .logo img {
    max-width: 453px;
    padding: 12px 0px 15px 30px;
  }

  .show-btn {
    position: fixed;
    top: 0.5%;
    right: 2.5%;
    font-size: 30px;
    color: black;
    z-index: 6;
    display: block;
  }

  .info-container {
    padding: 71px 0px !important;
  }

  .info-desc {
    max-width: 746px;
  }

  .contact_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 711px;
  }

  iframe {
    max-width: 703px;
  }

  .contact_form {
    width: 482px;
    padding: 31px 31px 37px 31px;
  }

  .contact_form h2 {
    font-size: 48px;
  }

  .contact_form p {
    font-size: 19px;
  }

  .contact_form input {
    padding: 18px 0px 18px 20px;
    font-size: 20px;
  }

  .contact_form .input_field i.input-icons {
    font-size: 22px;
  }

  .contact_form textarea {
    margin: 21px 0px;
    width: 100%;
  }

  .submit_btn button {
    padding: 21px 40px;
    font-size: 24px;
  }

  .map {
    height: auto;
  }

  .footer_menu ul {
    padding-left: 0px;
  }

  .footer_menu ul a {
    font-size: 14px;
  }

  .get_d_fex {
    gap: 14%;
  }

  .banner-slider-image img {
    width: 100%;
    height: 324px;
  }

  /* .Top-header-section-folder {
    display: none;
  } */

  .board-of-members-folder .slick-prev {
    left: 5px;
  }

  .board-of-members-folder .slick-next {
    right: 4px;
  }

  .gallery-slider-image {
    width: 100%;
    height: 433px;
    max-width: 100%;
    width: 100%;
    height: 433px;
    width: 100%;
    height: 433px;
  }

  .gallery-slider-image iframe {
    width: 768px !important;
    height: 100%;
    width: 768px !important;
    height: 100%;
    max-width: 100%;
    width: 768px !important;
    height: 100%;
  }

  .about_us_img {
    max-width: 100%;
    width: 100%;
  }

  .about_us_img img {
    max-width: 100%;
    width: 100%;
  }

  .artical-blog-folder {
    gap: 15px;
  }

  .Articles-image img {
    width: 100%;
    height: auto;
  }

  .Articles-blog-section {
    max-width: 723px;
  }

  .Articles-blog-section h2 {
    font-size: 37px;
  }

  .Articles-date {
    font-size: 13px;
  }

  .page-membership-details .about_us,
  .page-ta-101-program-details .about_us,
  .page-exam-dates .about_us,
  .page-saata-calendar .about_us,
  .page-examination-forms .about_us,
  .page-examination-documents .about_us,
  .page-directory .about_us,
  .page-membership-renewal .about_us,
  .page-call-for-nominations .about_us,
  .page-guidelines-for-content-contributors .about_us,
  .page-sajta-submission-guidelines .about_us,
  .page-newsletter-advertisement-policy .about_us,
  .page-journals .about_us,
  .page-awards .about_us,
  .page-service-award .about_us,
  .page-about-us .about_us,
  .page-about-ta .about_us,
  .page-history-and-origin-of-saata .about_us,
  .page-presidents-note .about_us,
  .page-mission-and-vision .about_us,
  .page-eric-berne .about_us,
  .page-saata-bot .about_us,
  .page-enthusiastic-explorer .about_us,
  .page-exuberant-organiser .about_us,
  .page-story-teller .about_us,
  .page-truth-seeker .about_us,
  .page-ace-conceptualist .about_us,
  .page-gregarious-hugger .about_us,
  .page-team-that-was .about_us,
  .page-membership-details .about_us,
  .page-ta-101-program-details .about_us,
  .page-exam-dates .about_us,
  .page-saata-calendar .about_us,
  .page-examination-forms .about_us,
  .page-examination-documents .about_us,
  .page-directory .about_us,
  .page-membership-renewal .about_us,
  .page-call-for-nominations .about_us,
  .page-guidelines-for-content-contributors .about_us,
  .page-sajta-submission-guidelines .about_us,
  .page-newsletter-advertisement-policy .about_us,
  .page-journals .about_us,
  .page-awards .about_us,
  .page-service-award .about_us,
  .page-about-us .about_us,
  .page-about-ta .about_us,
  .page-history-and-origin-of-saata .about_us,
  .page-presidents-note .about_us,
  .page-mission-and-vision .about_us,
  .page-eric-berne .about_us,
  .page-saata-bot .about_us,
  .page-enthusiastic-explorer .about_us,
  .page-exuberant-organiser .about_us,
  .page-story-teller .about_us,
  .page-truth-seeker .about_us,
  .page-ace-conceptualist .about_us,
  .page-gregarious-hugger .about_us,
  .page-team-that-was .about_us,
  .page-saata-conferences .about_us.about_ta {
    max-width: 740px !important;
  }

  .page-membership-details .about_us .about_us_wrapper h2,
  .page-ta-101-program-details .about_us .about_us_wrapper h2,
  .page-exam-dates .about_us .about_us_wrapper h2,
  .page-saata-calendar .about_us .about_us_wrapper h2,
  .page-examination-forms .about_us .about_us_wrapper h2,
  .page-examination-documents .about_us .about_us_wrapper h2,
  .page-directory .about_us .about_us_wrapper h2,
  .page-membership-renewal .about_us .about_us_wrapper h2,
  .page-call-for-nominations .about_us .about_us_wrapper h2,
  .page-guidelines-for-content-contributors .about_us .about_us_wrapper h2,
  .page-sajta-submission-guidelines .about_us .about_us_wrapper h2,
  .page-newsletter-advertisement-policy .about_us .about_us_wrapper h2,
  .page-journals .about_us .about_us_wrapper h2,
  .page-awards .about_us .about_us_wrapper h2,
  .page-service-award .about_us .about_us_wrapper h2,
  .page-about-us .about_us .about_us_wrapper h2,
  .page-about-ta .about_us .about_us_wrapper h2,
  .page-history-and-origin-of-saata .about_us .about_us_wrapper h2,
  .page-presidents-note .about_us .about_us_wrapper h2,
  .page-mission-and-vision .about_us .about_us_wrapper h2,
  .page-eric-berne .about_us .about_us_wrapper h2,
  .page-saata-bot .about_us .about_us_wrapper h2,
  .page-enthusiastic-explorer .about_us .about_us_wrapper h2,
  .page-exuberant-organiser .about_us .about_us_wrapper h2,
  .page-story-teller .about_us .about_us_wrapper h2,
  .page-truth-seeker .about_us .about_us_wrapper h2,
  .page-ace-conceptualist .about_us .about_us_wrapper h2,
  .page-gregarious-hugger .about_us .about_us_wrapper h2,
  .page-team-that-was .about_us .about_us_wrapper h2 {
    font-size: 34px;
  }

  .page-awards .about_us.about_ta .about_us_content img,
  .page-service-award .about_us.about_ta .about_us_content img,
  .page-eric-berne .about_us.about_ta .about_us_content img {
    width: 341px !important;
  }
}

.page-saata-calendar th p,
.page-saata-calendar td p {
  text-align: left;
}

.page-saata-calendar th,
.page-saata-calendar td,
.page-saata-calendar tr,
.page-sajta-event tr,
.page-sajta-event td{
  border: 1px solid #333 !important;
}

@media only screen and (max-width: 767px) {
  .banner-slider-image img {
    width: 100%;
    height: 200px;
  }

  .info-container {
    padding: 30px 0px !important;
  }

  .info-title {
    font-size: 27px;
  }

  .info-desc {
    font-size: 14px;
    max-width: 327px;
    margin: 34px auto 20px auto;
  }

  .read-more {
    padding: 11px 24px;
    font-size: 12px;
  }

  .board-of-members-folder {
    display: block;
  }

  .board-of-members-folder .slick-prev {
    left: 5px;
  }

  .board-of-members-folder .slick-next {
    right: 4px;
  }

  .board-of-members-section {
    padding: 57px 0px !important;
  }

  .board-of-members-blog-box-section h2 {
    margin: 25px 0px 0 0px;
  }

  .contact_form {
    width: 329px;
    padding: 31px 20px 37px 20px;
  }

  .contact_form h2 {
    font-size: 37px;
  }

  .contact_form p {
    font-size: 15px;
  }

  .contact_form input {
    padding: 15px 0px 15px 13px;
    font-size: 12px;
  }

  .contact_form .input_field i.input-icons {
    font-size: 17px;
  }

  .contact_form textarea {
    width: 290px;
    font-size: 12px;
  }

  .submit_btn button {
    padding: 13px 40px;
    font-size: 18px;
  }

  iframe {
    max-width: 331px;
  }

  .footer_wrapper {
    margin: 0px 15px;
  }

  .get_d_fex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer_menu ul a {
    font-size: 11px;
  }

  .logo {
    padding-left: 10px;
  }

  .logo img {
    max-width: 273px;
  }

  .menu {
    padding-left: 0px !important;
  }

  .menu li {
    margin: 4px 0px;
    border-bottom: 1px solid;
    width: 100%;
    text-align: center;
    padding-bottom: 15px;
  }

  .menu li a {
    font-size: 19px;
  }

  .show-btn {
    font-size: 23px;
    z-index: 8;
  }

  .banner-slider-folder .slick-prev {
    left: 11px;
    z-index: 6;
  }

  .banner-slider-folder .slick-prev:before {
    font-size: 24px;
    font-size: 24px;
  }

  .banner-slider-folder .slick-next:before {
    font-size: 24px;
    font-size: 24px;
  }

  .gallery-slider-image {
    width: 100%;
    height: 310px;
  }

  .gallery-slider-image iframe {
    width: 100% !important;
    height: 100%;
    max-width: 100%;
  }

  .ITAA-The-Dance-of-Culture-container h2 {
    font-size: 16px;
    max-width: 320px;
    margin: 49px auto 43px auto;
  }

  .gallery-South-Asian-Association-container h2 {
    font-size: 16px;
    max-width: 320px;
    margin: 49px auto 43px auto;
  }

  .ITAA-The-Dance-of-Culture-folder {
    max-width: 347px;
    margin: 0px auto;
  }

  .ITAA-The-Dance-of-Culture-folder
    .slick-slide.slick-active.slick-center.slick-current
    .ITAA-The-Dance-of-Culture-image {
    max-width: 305px;
  }

  .gallery-South-Asian-Association-folder {
    max-width: 347px;
    margin: 0px auto;
  }

  .gallery-South-Asian-Association-folder
    .slick-slide.slick-active.slick-center.slick-current
    .gallery-South-Asian-Association-image {
    max-width: 305px;
  }

  .ITAA-The-Dance-of-Culture-image {
    max-width: 289px;
    margin: 0px auto;
  }

  .gallery-South-Asian-Association-image {
    max-width: 289px;
    margin: 0px auto;
  }

  .gallery-slider-folder .slick-prev {
    left: 11px;
    z-index: 6;
  }

  .about_us_img {
    width: 100%;
  }

  .about_us_img img {
    width: 100%;
  }

  .about_us::before {
    height: 150px;
  }

  .Top-Header-wrapper {
    padding: 10px 0px 0px 0px;
  }

  .Top-header-section {
    z-index: 7;
  }

  .artical-blog-box-section {
    width: 336px;
  }

  .artical-text {
    font-size: 31px;
  }

  .artical-author-image img {
    width: 55px;
    height: 55px;
  }

  .Articles-blog-section {
    max-width: 342px;
  }

  .Articles-blog-section h2 {
    font-size: 22px;
  }

  .Articles-date {
    font-size: 8px;
  }

  .Articles-image {
    margin: 33px 0px 23px 0px;
  }

  .Articles-image img {
    width: 100%;
    height: auto;
  }

  .Articles-Author {
    font-size: 13px;
    line-height: 25px;
  }

  .Articles-des {
    font-size: 13px;
    line-height: 25px;
  }

  .page-exam-dates .about_us.about_ta,
  .page-examination-documents .about_us.about_ta,
  .page-examination-forms .about_us.about_ta,
  .page-membership-renewal .about_us.about_ta,
  .page-directory .about_us.about_ta,
  .page-call-for-nominations .about_us.about_ta,
  .page-guidelines-for-content-contributors .about_us.about_ta,
  .page-sajta-submission-guidelines .about_us.about_ta,
  .page-newsletter-advertisement-policy .about_us.about_ta,
  .page-journals .about_us.about_ta,
  .page-saata-calendar .about_us.about_ta,
  .page-awards .about_us.about_ta,
  .page-service-award .about_us.about_ta,
  .page-about-us .about_us.about_ta,
  .page-about-ta .about_us.about_ta,
  .page-history-and-origin-of-saata .about_us.about_ta,
  .page-presidents-note .about_us.about_ta,
  .page-mission-and-vision .about_us.about_ta,
  .page-eric-berne .about_us.about_ta,
  .page-membership-details .about_us.about_ta,
  .page-ta-101-program-details .about_us.about_ta,
  .page-saata-calendar .about_us.about_ta,
  .page-saata-bot .about_us.about_ta,
  .page-saata-conference .about_us.about_ta,
  .page-saata-conference-2025 .about_us.about_ta,
  .page-sajta-event  .about_us.about_ta{
    padding: 18px 0px;
    max-width: 318px !important;
  }

  .page-exam-dates .about_us.about_ta {
    padding: 18px 0px;
  }

  .membreship-registration-wrapper {
    max-width: 288px;
    margin: 32px auto 0px auto;
  }

  .Membership-Registration-title,
  .Membership-Registration-text,
  .Membership-Registration-text a {
    font-size: 19px;
    margin-left: 0px;
  }

  .membership-registration-card-folder {
    margin: 34px 0px 50px 0px;
  }

  .Certified-Members-text {
    font-size: 42px;
    max-width: 280px;
    text-align: center;
    margin: 23px auto 27px auto;
  }

  .news-main h1 {
    padding: 18px 0px !important;
    font-size: 16px !important;
    margin-bottom: 0px !important;
  }

  .news-letter-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .news-letter-folder1 {
    width: 100%;
  }

  .news-letter-subtitle p,
  .newsletter-description p {
    line-height: 31px !important;
    font-size: 13px !important;
  }

  .news-letter-subtitle {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .news-letter-folder1 {
    width: 100% !important;
  }

  .newsletter-description {
    margin-left: 0px !important;
  }

  .news-letter-folder2 {
    width: 100% !important;
  }

  .page-membership-details .about_us,
  .page-ta-101-program-details .about_us,
  .page-exam-dates .about_us,
  .page-saata-calendar .about_us,
  .page-examination-forms .about_us,
  .page-examination-documents .about_us,
  .page-directory .about_us,
  .page-membership-renewal .about_us,
  .page-call-for-nominations .about_us,
  .page-guidelines-for-content-contributors .about_us,
  .page-sajta-submission-guidelines .about_us,
  .page-newsletter-advertisement-policy .about_us,
  .page-journals .about_us,
  .page-awards .about_us,
  .page-service-award .about_us,
  .page-sajta-event .about_us {
    max-width: 342px;
    margin: 0px auto;
  }

  .page-exam-dates .about_us.about_ta,
  .page-examination-documents .about_us.about_ta,
  .page-directory .about_us.about_ta,
  .page-membership-renewal .about_us.about_ta,
  .page-call-for-nominations .about_us.about_ta,
  .page-guidelines-for-content-contributors .about_us.about_ta,
  .page-sajta-submission-guidelines .about_us.about_ta,
  .page-newsletter-advertisement-policy .about_us.about_ta,
  .page-journals .about_us.about_ta,
  .page-awards .about_us.about_ta,
  .page-service-award .about_us.about_ta {
    padding: 18px 0px;
    font-size: 25px !important;
  }

  .page-awards .about_us.about_ta .about_us_content img,
  .page-service-award .about_us.about_ta .about_us_content img,
  .page-eric-berne .about_us.about_ta .about_us_content img,
  .page-crossword .newsletter-description img {
    width: 100% !important;
  }

  .page-exam-dates .about_us.about_ta,
  .page-examination-documents .about_us.about_ta,
  .page-directory .about_us.about_ta,
  .page-membership-renewal .about_us.about_ta,
  .page-call-for-nominations .about_us.about_ta,
  .page-guidelines-for-content-contributors .about_us.about_ta,
  .page-sajta-submission-guidelines .about_us.about_ta,
  .page-newsletter-advertisement-policy .about_us.about_ta,
  .page-journals .about_us.about_ta,
  .page-awards .about_us.about_ta,
  .page-service-award .about_us.about_ta,
  .page-about-us .about_us.about_ta,
  .page-about-ta .about_us.about_ta,
  .page-history-and-origin-of-saata .about_us.about_ta,
  .page-presidents-note .about_us.about_ta,
  .page-mission-and-vision .about_us.about_ta,
  .page-eric-berne .about_us.about_ta,
  .page-saata-bot .about_us.about_ta,
  .page-enthusiastic-explorer .about_us.about_ta,
  .page-exuberant-organiser .about_us.about_ta,
  .page-story-teller .about_us.about_ta,
  .page-truth-seeker .about_us.about_ta,
  .page-ace-conceptualist .about_us.about_ta,
  .page-gregarious-hugger .about_us.about_ta {
    padding: 18px 0px !important;
  }

  .page-guidelines-for-content-contributors
    .about_us.about_ta
    .about_us_content
    ul,
  .page-sajta-submission-guidelines .about_us.about_ta .about_us_content ul,
  .page-about-us .about_us.about_ta .about_us_content ul,
  .page-mission-and-vision .about_us.about_ta .about_us_content ul,
  .page-membership-details .about_us.about_ta .about_us_content ul,
  .page-ta-101-program-details .about_us.about_ta .about_us_content ul {
    transform: translateX(0px);
  }

  .page-guidelines-for-content-contributors
    .about_us.about_ta
    .about_us_content
    ul
    li,
  .page-sajta-submission-guidelines .about_us.about_ta .about_us_content ul li,
  .page-about-us .about_us.about_ta .about_us_content ul li,
  .page-mission-and-vision .about_us.about_ta .about_us_content ul li,
  .page-membership-details .about_us.about_ta .about_us_content ul li,
  .page-ta-101-program-details .about_us.about_ta .about_us_content ul li {
    list-style: none;
  }

  .page-membership-details .about_us .about_us_wrapper h2,
  .page-ta-101-program-details .about_us .about_us_wrapper h2,
  .page-exam-dates .about_us .about_us_wrapper h2,
  .page-saata-calendar .about_us .about_us_wrapper h2,
  .page-examination-forms .about_us .about_us_wrapper h2,
  .page-examination-documents .about_us .about_us_wrapper h2,
  .page-directory .about_us .about_us_wrapper h2,
  .page-membership-renewal .about_us .about_us_wrapper h2,
  .page-call-for-nominations .about_us .about_us_wrapper h2,
  .page-guidelines-for-content-contributors .about_us .about_us_wrapper h2,
  .page-sajta-submission-guidelines .about_us .about_us_wrapper h2,
  .page-newsletter-advertisement-policy .about_us .about_us_wrapper h2,
  .page-journals .about_us .about_us_wrapper h2,
  .page-awards .about_us .about_us_wrapper h2,
  .page-service-award .about_us .about_us_wrapper h2,
  .page-about-us .about_us .about_us_wrapper h2,
  .page-about-ta .about_us .about_us_wrapper h2,
  .page-history-and-origin-of-saata .about_us .about_us_wrapper h2,
  .page-presidents-note .about_us .about_us_wrapper h2,
  .page-mission-and-vision .about_us .about_us_wrapper h2,
  .page-eric-berne .about_us .about_us_wrapper h2,
  .page-saata-bot .about_us .about_us_wrapper h2,
  .page-enthusiastic-explorer .about_us .about_us_wrapper h2,
  .page-exuberant-organiser .about_us .about_us_wrapper h2,
  .page-story-teller .about_us .about_us_wrapper h2,
  .page-truth-seeker .about_us .about_us_wrapper h2,
  .page-ace-conceptualist .about_us .about_us_wrapper h2,
  .page-gregarious-hugger .about_us .about_us_wrapper h2,
  .page-history-and-origin-of-saata .about_us .about_us_wrapper h2  {
    font-size: 25px !important;
  }
  .page-saata-conference-2025 .about_us .about_us_wrapper h2  {
    font-size: 27px !important;
  }
  .page-cpnews .news-latter-cpnews-section {
    max-width: 318px !important;
  }

  .page-cpnews .news-latter-cpnews-section ul {
    display: flex;
    justify-content: center;
  }

  .page-cpnews .news-latter-cpnews-section h2 {
    margin-left: 0px;
  }

  .news-letter-team-wrapper {
    display: flex;
    flex-direction: column;
  }

  .newsletterTeam-folder {
    width: 100%;
  }

  .news-letter-team {
    width: 100%;
  }

  .newsletterTeam-folder ul {
    display: flex;
    flex-wrap: wrap;
  }

  .newsletterTeam-folder ul li {
    width: 163px;
  }

  .newsletterTeam-folder ul li a {
    font-size: 12px;
  }

  .page-membership-details .about_us_content table,
  .page-ta-101-program-details .about_us_content table,
  .page-saata-calendar .about_us_content table,
  .page-saata-bot .about_us_content table,
  .page-sajta-event .about_us_content table,
  table {
    display: block !important;
    overflow-y: scroll !important;
  }
  #tw-target-text a {
    display: block;
    margin: 10px !important;
    text-align: center;
  }
}

.Top-Header-wrapper-folder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  max-width: 100%;
}

.menu-toggle {
  display: none;
}

.menu-folder {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-item {
  position: relative;
}

.menu-item > a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #000;
}

.menu-item:hover > a {
  background-color: #f0f0f0;
}

.menu-folder ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.menu-item:hover ul {
  display: block;
}

.menu-folder ul li {
  width: 100%;
}

.menu-folder ul a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #000;
}

.menu_item ul {
  width: 150px;
}

.menu_item ul li a {
  padding: 10px 0px;
}

.page-governance .about_us::before {
  background: none;
}

.page-governance .about_us_wrapper h2 {
  color: #000;
}

.page-governance p {
  display: flex;
}

.page-governance p a {
  padding: 9px 0px !important;
  width: 383px !important;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  background: #ab79b4;
  text-decoration: none;
  color: #fff;
}
.page-memorial-endowment-lecture .about_us_img,
.page-p-k-saru-memorial-endowment-fund .about_us_img {
  max-width: 100%;
  width: 100%;
}

.page-memorial-endowment-lecture .about_us_img h3,
.page-p-k-saru-memorial-endowment-fund .about_us_img h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}

.page-memorial-endowment-lecture .about_us_content i,
.page-p-k-saru-memorial-endowment-fund .about_us_content i {
  color: red;
  font-style: normal;
}
@media (max-width: 768px) {
  .collapsible .button {
    font-size: 15px;
  }
  .Top-Header-wrapper-folder {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-toggle {
    display: block;
    padding: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 6%;
  }

  .logo img,
  .logo {
    padding: 0;
  }

  .menu-toggle-bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #000;
    margin-bottom: 5px;
  }

  .menu-folder {
    display: none;
    flex-direction: column;
    /* position: absolute; */
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 20px !important;
  }

  .menu-open {
    display: block;
  }

  .menu-folder ul {
    position: static;
    box-shadow: none;
  }

  .menu-folder ul li {
    width: 100%;
    border-bottom: 1px solid #ccc;
  }

  .menu_item ul {
    width: 100%;
  }

  .ITAA-The-Dance-of-Culture-image {
    display: block !important;
  }

  .Top-Header-wrapper-folder .social_icon {
    position: fixed;
    top: 50%;
    right: 0;
    flex-direction: column;
    gap: 12px;
    z-index: 99;
  }

  .Top-Header-wrapper-folder .social_icon a {
    width: 30px;
    height: 30px;
  }
}

.login_btn a {
  color: #fff !important;
}

.video .info-container {
  text-align: center;
  padding: 55px 0px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

/* Submenu styles */
.News_hover {
  position: relative;
}

.Newsletter:hover .hover {
  display: none;
}

.hover {
  display: none;
  position: absolute;
  top: 0px;
  left: 100%;
  background-color: #f2f2f2;
  padding: 10px;
}

/* .hover li {
  display: block;
} */

.hover li a {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
}

.News_hover:hover .hover,
.directory:hover .hover {
  display: block;
  left: 100%;
  top: 0;
}

.contact_form input.submit-btn {
  padding: 14px 40px;
  text-align: center;
  background: white;
  cursor: pointer;
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  color: #a37bb6;
  border-radius: 15px;
}

.page-call-for-proposals p a {
  padding: 10px;
  text-align: center;
  background: #a37bb6;
  cursor: pointer;
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  color: #fff;
  text-decoration: none;
  margin-bottom: 20px;
}

.info-desc p a {
  text-decoration: none;
  font-weight: bold;
  color: #fff;
}

.info-desc p a:hover {
  border-bottom: 1px solid;
}

.page-saata-conference .about_us_img,
.page-saata-conference-2025 .about_us_img,
.about_us_img img {
  width: 100%;
  max-width: 100%;
}

/* .custom-rich-text-image img {
  width: 100%;
} */

.custom-rich-text-pdf,
.custom-rich-text-image {
  margin: 30px 0;
}

.page-saata-conference table {
  margin: 10px 0;
}
.single-video{
  background-color: #a37bb6;
    color: white;
    padding: 20px;
}
.single-video iframe{
 display: flex;
 margin: auto;
}
.input-group .btn {
  background: #a37bb6;
  border: none;
  z-index: 0 !important;
}
.excel-display .about_us_content p {
  margin-bottom: 30px;
}
.excel-display .about_us_wrapper h2 {
  margin-left: 0px;
}
.excel-display{
  padding: 18px 30px !important;
  background-color: rgba(249, 249, 249, 0.2392156863);
 box-shadow: 0px 4px 17px #e5e5e5;
 max-width: 1258px !important;
}
.excel-display .about_us_content p a {
  color: #a37bb6 !important;
}
.page-saata-conference-2025 .about_us .about_us_wrapper h2 {
  margin-bottom: 35px;
}
.page-saata-conference-2025 .about_us_img {
  margin-bottom: 40px;
}
.page-saata-conference-2025 .about_us .about_us_content p a{
  color: #a37bb6 !important;
  font-weight: 500;
}
.address-line {
  font-size:18px;
  margin-bottom: 5px;
  color: rgb(111, 111, 111);
  font-weight: 600;
}
.address-line span {
  font-weight: normal;
}
.address-line p {
 margin-bottom: 10px;
}
.map {
  margin-top: 3px;
  padding: 19px 20px 39px 20px;
  border-radius: 15px;
  
}
.address-title p {
  margin-bottom: 12px;
}
.address-title p::before{
  content: "\f041";
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  font-family: FontAwesome;
  margin-right: 6px;
}

.address-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  align-items: flex-end;
  margin-bottom: 10px;
}

.contact_wrapper {
  display: block;
}
.input_wrapper {
  gap: 0px;
  justify-content: space-between;
}
.contact_form .input_field {
  margin: inherit;
}
.contact_form input {
  width: 450px;
}
.contact_form {
  width: 1000px;
  margin: auto;
}
.contact_form input.submit-btn {
  width: 300px;
}

.page-saata-conference .about_us_content ul{
  list-style: inherit;
}
.page-saata-conference .about_us_content a {
  color: #0d6efd !important;
  font-weight: 700;
}

@media (max-width: 768px) {
  .excel-display {
    max-width: 380px !important;
}
}
.ticket-button-container{
  margin: 10px auto;
  padding: 20px 0;
  text-align: center;
}

.disabled-link {
  /* cursor: not-allowed; */
  text-decoration: none;
  padding: 10px 20px;
  color:#fff;
  border-radius: 8px;
  border: none;
  margin: auto;
  background: #a37bb6;
  text-transform: uppercase;
}
.preview-btn,
.download-btn{
  padding: 8px 20px;
  border-radius: 8px;
  border: none;
  background: #a37bb6;
}
.error{
  color: red;
}
.certificate h1{
  color: #a37bb6;
 font-size: 30px;
}
.success{
  color: green;
}
.page-generate-your-certificate .certificate input{
  width: 74.8%;
}
.error{
   width: 50%;
}
.button-wrapper{
  display: flex;
  gap: 5px;
}
.reach-text{
  margin: 10px 0;
}
.reach-text a{
  text-decoration: underline;
}
.page-payment .email a,
.page-payment .phone a{
  color:#a37bb6 ;
}
.page-payment .email a::before{
  content: "\f003";
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  font-family: FontAwesome;
  margin-right: 6px;
}
.page-payment .phone a::before{
  content: "\f095";
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  font-family: FontAwesome;
  margin-right: 6px;
}
@media (max-width: 768px) {
  .certificate input,
  .error{
    width: 100%;
  }
  .contact_form{
    width: 100%;
  }
  .contact_form .input_wrapper{
    flex-direction: column;
    gap: 20px;
  }
  .contact_form textarea{
    width: 100%;
  }
}

.input-field {
  @apply w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500;
}
