// $primary-color: #007bff;
.main-container {
  // background-color: $primary-color;
  color: #fff;
}

.page-membership-details,
.page-ta-101-program-details,
.page-exam-dates,
.page-saata-calendar,
.page-examination-forms,
.page-examination-documents,
.page-directory,
.page-membership-renewal,
.page-call-for-nominations,
.page-guidelines-for-content-contributors,
.page-sajta-submission-guidelines,
.page-newsletter-advertisement-policy,
.page-journals,
.page-awards,
.page-service-award,
.page-about-us,
.page-about-ta,
.page-history-and-origin-of-saata,
.page-presidents-note,
.page-mission-and-vision,
.page-eric-berne,
.page-saata-bot,
.page-enthusiastic-explorer,
.page-exuberant-organiser,
.page-story-teller,
.page-truth-seeker,
.page-ace-conceptualist,
.page-gregarious-hugger,
.page-team-that-was,
.page-editors-collage,
.page-governance,
.page-saata-conference,
.page-saata-conference-2025,
.page-sajta-event {
  .about_us {
    max-width: 1258px;
    margin: 0px auto;

    .about_us_wrapper {
      h2 {
        text-align: left;
        width: 100%;
        font-size: 30px;
        color: #a37bb6;
        margin-left: 20px;
        margin-top: 20px;
      }
    }

    .about_us_content {
      p {
        font-size: 15px;
        padding-bottom: 12px;
        margin-top: 0px !important;
      }

      ul {
        transform: translateX(21px);
      }

      table {
        border: 1px solid #cdcdcd;
        width: 100%;
        border-collapse: collapse;

        tr {
          th {
            border-bottom: 1px solid black;
            background-color: #a37bb6;
            color: white;

            p {
              padding: 20px 18px;
              font-size: 15px;
              margin-top: 0px !important;
            }
          }
        }

        tr:nth-child(odd) {
          background-color: #a37bb64d;
        }

        tr {
          td {
            padding: 20px 0px;
            font-size: 15px;
            border-bottom: 1px solid #cdcdcd;

            p {
              padding: 0px 18px;

              a {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  .about_us::before {
    background: none;
  }
}

.page-ta-101-program-details {
  .about_us_content {
    table {
      margin: 60px 0px 30px 0px;

      tr {
        th {
          p {
            text-align: center;
          }
        }
      }

      tr {
        td {
          p {
            text-align: center;
          }
        }
      }
    }
  }
}

.newsletter-description {
  margin-left: 0px;

  ul {
    margin-left: 24px;
  }

  b {
    display: block;
  }
}

$color_1: black;
$color_2: white;
$background-color_1: rgb(232, 76, 61);

* {
  margin: 0px;
  padding: 0;
  box-sizing: border-box;
}

.news-letter-subtitle {
  display: flex;
  justify-content: space-between;
  margin: auto;
  gap: 19px;

  img {
    max-width: 351px;
  }

  p {
    line-height: 36px;
    font-size: 15px;
  }
}

.news-main {
  max-width: 1276px;
  margin: 0px auto;
  padding: 0px 12px;

  h1 {
    border: 1px solid;
    text-align: center;
    padding: 10px 0px;
    background-color: $background-color_1;
    color: $color_2;
    font-size: 29px;
    margin-bottom: 20px;
  }
}

.newsletter {
  max-width: 1276px;
  margin: 0px auto;
  padding: 0px 12px;

  a {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0px;
    font-size: 20px;
    color: $color_1;
    text-decoration: none;
  }
}

.newsletter-description {
  p {
    margin-bottom: 15px;
    line-height: 26px;
    font-size: 15px;

    &:nth-child(8) {
      display: flex;
      flex-direction: column;
    }
  }
}

.newsletter_banner_img {
  img {
    width: 100%;
    margin-bottom: 10px;
  }
}

.news-letter-container {
  display: flex;
  width: 100%;
  gap: 27px;
}

.news-letter-folder1 {
  width: 77%;
}

.news-letter-folder2 {
  width: 23%;
}

.news-field {
  list-style: none;
  padding: 17px;
  text-align: center;
  margin: 25px 0px;
  background-color: $background-color_1;
  color: $color_2;
}

.news-drop_drown-menu {
  padding-left: 24px;

  li {
    margin-bottom: 10px;
  }
}

.news-latter-right-field-folder {
  ul {
    div {
      &:nth-child(2) {
        .news-field {
          background: #438b8b;
        }
      }

      &:nth-child(3) {
        .news-field {
          background: #da9a31;
        }
      }

      &:nth-child(4) {
        .news-field {
          background: #996ada;
        }
      }

      &:nth-child(5) {
        .news-field {
          background: #9cc255;
        }
      }
    }
  }
}

.page-exam-dates,
.page-examination-documents,
.page-directory,
.page-membership-renewal,
.page-call-for-nominations,
.page-guidelines-for-content-contributors,
.page-sajta-submission-guidelines,
.page-newsletter-advertisement-policy,
.page-journals,
.page-awards,
.page-service-award,
.page-about-us,
.page-about-ta,
.page-history-and-origin-of-saata,
.page-presidents-note,
.page-mission-and-vision,
.page-eric-berne,
.page-saata-bot,
.page-enthusiastic-explorer,
.page-exuberant-organiser,
.page-story-teller,
.page-truth-seeker,
.page-ace-conceptualist,
.page-gregarious-hugger,
.page-team-that-was,
.page-editors-collage,
.page-governance,
.page-saata-conference,
.page-saata-conference-2025 {
  .about_us.about_ta {
    padding: 18px 20px;
    background-color: #f9f9f93d;
    box-shadow: 0px 4px 17px #e5e5e5;
    margin-bottom: 37px;
  }
}

.page-from-the-editorial-desk,
.page-news-from-the-research-and-publications-team,
.page-operations-team,
.page-dev-team-updates,
.page-miscellaneous-news,
.page-saata-conference-updates,
.page-professional-standards-division-psd,
.page-where-theres-a-will-theres-a-way,
.page-unmute-please,
.page-cartoon-quip,
.page-from-the-presidents-desk,
.page-poem-the-collector-of-stamps,
.page-book-review,
.page-crossword {
  .news-latter-right-field-folder {
    .news-drop_drown-menu {
      li {
        a {
          color: #a37bb6;
        }
      }
    }

    img {
      width: 100%;
    }
  }
}

.page-SAJTATeam .Certified-Members-text {
  max-width: 260px;
}

.page-examination-forms {
  .about_us.about_ta {
    .about_us_content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;

      h3 {
        width: 289px;
        padding: 88px 26px;
        line-height: 30px;
        color: #757575;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 15px;
        box-shadow: 0px 4px 13px #e5e5e5;
        transition: 0.1s ease-in;
        background-color: #f9f9f921;

        a {
          color: #a37bb6;
          text-decoration: none;
        }

        &:hover {
          background-color: #a37bb6;
          color: white;

          a {
            color: white;
            text-decoration: revert;
          }
        }
      }
    }
  }
}

.page-examination-documents {
  .about_us.about_ta {
    .about_us_content {
      h3 {
        font-size: 17px;
        font-weight: 600;

        a {
          color: #a37bb6;
          font-weight: 400;
        }
      }

      p {
        a {
          color: #a37bb6;
        }
      }
    }
  }
}

.page-directory,
.page-call-for-nominations {
  .aboutus_parent {
    .about_us_img {
      max-width: 100%;
      width: 100%;
      margin: auto;
      margin-bottom: 16px;
    }
  }
}

.page-membership-renewal {
  .about_us_content {
    h2 {
      font-size: 22px;
      margin-bottom: 7px;
    }

    p {
      a {
        color: #a37bb6;
      }
    }
  }
}

.page-call-for-nominations {
  .about_us.about_ta {
    .about_us_content {
      p:nth-child(16) a {
        color: #a37bb6;
      }
    }
  }
}

.page-journals {
  .about_us.about_ta {
    .about_us_content {
      p {
        line-height: 24px;

        a {
          color: #a37bb6;
        }
      }

      h3 {
        margin-bottom: 17px;
      }
    }
  }
}

.page-awards,
.page-service-award,
.page-team-that-was {
  .about_us.about_ta {
    .about_us_content {
      h3 {
        margin-bottom: 14px;
      }

      p {
        line-height: 23px;
      }

      img {
        width: 428px;
        margin-bottom: 20px;
        margin-right: 20px;
      }
    }
  }
}

#SAJTA_team {
  p {
    text-align: justify;
  }

  .Certified-Members-folder {
    div {
      div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.page-CertifiedMembers,
.page-SAJTATeam {
  .Certified-Members-folder {
    div {
      position: relative;
      width: 352px;
      padding-bottom: 15px;
      border-radius: 15px;
      box-shadow: 0px 4px 7px #dbdbdb;

      .certified-members-image-folder {
        display: flex;
        flex-direction: column-reverse;
        border: none;
        width: 100%;
        box-shadow: none;

        h2 {
          font-size: 20px;
          margin: 14px 0px 0px 16px;
        }

        p {
          display: none;
        }
      }

      div:nth-child(1) {
        position: absolute;
        top: 0%;
        left: 0%;
        right: 0%;
        bottom: 0%;
        width: 100%;
        height: 100%;
        background-color: #a37bb6;
        color: white;
        z-index: 1;
        transition: all 0.2s ease-in;
        opacity: 0;
        padding: 24px 19px;

        ul {
          li {
            list-style: none;
            margin-bottom: 10px;
            line-height: 22px;
          }
        }
      }

      .certified-members-image-folder img {
        width: 100%;
        height: 368px;
        border-radius: 15px;
      }

      &:hover div:nth-child(1) {
        opacity: 1;
      }
    }
  }
}

.page-about-us,
.page-about-ta,
.page-history-and-origin-of-saata,
.page-presidents-note,
.page-mission-and-vision,
.page-eric-berne,
.page-saata-bot,
.page-team-that-was {
  .aboutus_parent {
    .about_us_content {
      margin: 0px auto 50px auto;
      line-height: 26px;
    }
  }
}

.page-about-ta,
.page-history-and-origin-of-saata,
.page-saata-bot {
  .about_us_img {
    max-width: 100%;
    width: 100%;
    margin-bottom: 15px;
  }
}

.page-infectious-workaholic,
.page-enthusiastic-explorer,
.page-exuberant-organiser,
.page-story-teller,
.page-truth-seeker,
.page-ace-conceptualist,
.page-gregarious-hugger {
  .news-letter-team-wrapper {
    display: flex;
    max-width: 1258px;
    margin: 0px auto;
    padding: 18px 20px;
    background-color: #f9f9f93d;
    box-shadow: 0px 4px 17px #e5e5e5;
    margin-bottom: 37px;
    gap: 25px;
    padding-top: 40px;

    .newsletterTeam-folder {
      width: 20%;

      ul {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 4px 8px #e1e1e1;

        li {
          list-style: none;
          padding: 39px 18px;
          border-bottom: 1px solid #dfdfdf;

          a {
            text-decoration: none;
            color: #a37bb6;
            font-weight: 400;
            display: flex;
            flex-direction: column;
          }

          &:hover {
            border-left: 6px solid #a37bb6;
            transition: all 0.1s ease-in;

            a {
              margin-left: 20px;
              transition: all 0.1s ease-in;
            }
          }
        }
      }
    }

    .page-CertifiedMembers {
      .Certified-Members-folder {
        div .certified-members-image-folder {
          img {
            object-fit: cover;
          }
        }
      }
    }

    .news-letter-team {
      width: 80%;

      .new-letter-team-d-flex {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;

        .nes-letter-team-wrapper {
          h2 {
            margin-bottom: 4px;
            font-size: 27px;
          }

          h6 {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.news-letter-team {
  .description {
    p {
      margin-bottom: 12px;
      line-height: 28px;
      display: flex;
      flex-direction: column;
      gap: 9px;

      b {
        color: #a37bb6;
      }
    }
  }
}

.page-saata-bot {
  .about_us.about_ta {
    .about_us_content {
      table {
        margin-bottom: 30px;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.page-cpnews {
  .news-latter-cpnews-section {
    max-width: 1258px;
    padding: 18px 20px;
    background-color: #f9f9f93d;
    box-shadow: 0px 4px 17px #e5e5e5;
    margin: 0px auto;
    margin-bottom: 37px;

    h2 {
      width: 100%;
      font-size: 30px;
      color: #a37bb6;
      margin-left: 20px;
      margin-top: 20px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 26px;
      margin: 30px 0px 30px 0px;

      li {
        list-style: none;
        width: 222px;
        padding: 40px 0px;
        text-align: center;
        box-shadow: 0px 4px 13px #e5e5e5;

        a {
          text-decoration: none;
          color: #a37bb6;
        }

        &:hover {
          background-color: #a37bb6;
          transition: all 0.1s ease-in;

          a {
            color: white;
          }
        }
      }
    }
  }

  li.menu-item.active {
    display: block;
    background: red;
  }
}

.page-saata-bot {
  .about_us.about_ta {
    .about_us_content {
      table {
        tr:nth-child(1) {
          background-color: #a37bb6;

          td {
            color: white;
          }
        }
      }
    }
  }
}

#tw-target-text {
  padding: 30px 20px;
  background-color: #f9f9f93d;
  box-shadow: 0px 4px 17px #e5e5e5;
  margin-bottom: 37px;
  max-width: 1258px;
  margin: 0px auto 50px auto;
}

#tw-target-text {
  a {
    border-radius: 8px;
    border: none;
    background: #a37bb6;
    cursor: pointer;
    padding: 8px 10px;
    text-decoration: none;
    margin: 0 10px;
    color: #fff;
  }
}

.page-membership-renewal {
  .aboutus_parent {
    p {
      padding: 10px 0;
    }

    a {
      border-radius: 8px;
      border: none;
      background: #a37bb6;
      cursor: pointer;
      padding: 8px 10px;
      text-decoration: none;
      margin: 10px 0;
      color: #fff !important;
    }
  }
}

#Faq {
  text-align: center;
  font-size: 30px;
  margin-top: 20px;
}

.page-cartoon-quip {
  .newsletter-description {
    margin-left: 0px;

    img {
      width: 100%;
      margin-top: 24px;
    }
  }
}

.page-crossword {
  .newsletter-description {
    img {
      width: 523px;
    }
  }
}

.page-editors-collage {
  .about_us.about_ta {
    .about_us_img {
      margin: initial;
      max-width: 100%;
      width: 100%;

      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

// li.menu-item.active {
//   display: block;
//   background: red;
// }

.page-saata-conference .custom-rich-text-image img{
  width: 200px;
}
.page-saata-conference h1 b,
.page-saata-conference h2 b,
.page-saata-conference h3 b
{
  font-size: 22px;
  font-weight: 800;
  margin: 5px 0;
}